html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body, #root {
  height: 100%;
}

body {
  background-color: #fff;
  color: #111;
  font-size: 14px;
}

* {
  margin: 0;
  padding: 0;
}
